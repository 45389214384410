<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form autocomplete="off" ref="form" @submit.prevent="handleSubmit(save)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.lands') }}</strong></span>
					<span v-if="land">#{{ land.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<div class="columns">
						<div class="column">
							<InputWithValidation name="name" rules="required" :label="$tc('fields.name', 1)" v-model="land.name" />
							<div class="columns mt-1">
								<div class="column is-relative input-area">
									<b-tooltip class="tooltip-map" position="is-right" type="is-primary" multilined>
										<span class="badge-helper">?</span>
										<template v-slot:content>
											Utilize apenas <strong>ponto</strong> para separar as casas decimais.
										</template>
									</b-tooltip>
									<InputWithValidation name="area" rules="required" :label="$t('fields.area')" v-model="land.area" v-money="formatArea" :disabled="!user || name == 'Edit' && user.role.name != 'master'" />
								</div>
								<div class="column">
									<InputWithValidation name="capacity" field="number" :label="$t('fields.capacity')" :value="capacity.toFixed(2)" disabled />
								</div>
							</div>
							<div class="columns">
								<div class="column">
									<InputWithValidation name="city" rules="required" :label="$t('fields.city')" v-model="land.city" />
								</div>
								<div class="column">
									<SelectWithValidation name="state" :label="$tc('fields.state')" v-model="land.state">
										<option :value="s" v-for="(s, i) in states" :key="i">{{ s }}</option>
									</SelectWithValidation>
								</div>
							</div>

							<SelectWithValidation v-if="!showCoordinates" class="mb-4" label="Deseja cadastrar as coordenadas da propriedade agora?" v-model="showCoordinates">
								<option :value="0" key="cadcoord-0">{{ $t('buttons.no') }}</option>
								<option :value="1" key="cadcoord-1">{{ $t('buttons.yes') }}</option>
							</SelectWithValidation>

							<h3 v-if="showCoordinates" class="is-size-5 is-semibold has-text-primary title-helper">
								<span>Localização</span>
								<b-tooltip class="tooltip-map" position="is-right" type="is-primary" multilined>
									<span class="badge-helper">?</span>
									<template v-slot:content>
										Usado para inserir o <strong>ícone</strong> de marcação no mapa.
										<img src="@/assets/images/map.png" class="is-rounded" />
									</template>
								</b-tooltip>
							</h3>
							<hr v-if="showCoordinates" class="my-3">
							<div v-if="showCoordinates" class="columns">
								<div class="column">
									<InputWithValidation name="lat" rules="required" :label="$t('fields.latitude')" v-model="land.lat" />
								</div>
								<div class="column">
									<InputWithValidation name="lon" rules="required" :label="$t('fields.longitude')" v-model="land.lon" />
								</div>
							</div>
							<h3 v-if="showCoordinates" class="is-size-5 is-semibold has-text-primary title-helper">
								<span>Coordenadas</span>
								<b-tooltip class="tooltip-map" position="is-right" type="is-primary" multilined>
									<span class="badge-helper">?</span>
									<template v-slot:content>
										Usado para inserir os pontos de <strong>mapeamento</strong>.
										<img src="@/assets/images/map-2.png" class="is-rounded" />
									</template>
								</b-tooltip>
							</h3>
							<hr v-if="showCoordinates" class="my-3">
							<div v-if="showCoordinates" class="columns mb-0">
								<div class="column mb-0">
									<div class="field">
										<label class="label">{{ $t('fields.latitude') }}</label>
									</div>
								</div>
								<div class="column mb-0">
									<div class="field">
										<label class="label">{{ $t('fields.longitude') }}</label>
									</div>
								</div>
							</div>
							<div class="mb-5 pb-5" v-if="showCoordinates">
								<div v-for="(c, i) in coordinates" :key="`coord-${i}`" class="columns coordinate">
									<div class="column column-number pb-0">
										<span class="order">{{ i + 1 }}</span>
										<InputWithValidation name="lat[]" size="is-small" v-model="c.lat" />
									</div>
									<div class="column pb-0">
										<InputWithValidation name="lon[]" size="is-small" v-model="c.lon" />
									</div>
									<b-button v-if="i > 2" class="remove" @click="deleteCoordinate(c, i)">×</b-button>
								</div>
								<b-button type="is-primary" class="is-semibold pull-right" @click="addCoordinate()">{{ $t('labels.add_point') }}</b-button>
							</div>
							<SelectWithValidation class="mb-4" label="Qual documento deseja utilizar para comprovar sua propriedade?" v-model="land.user_document_id">
								<option v-for="d in documents" :key="d.id" :value="d.id">{{ d.name }}</option>
							</SelectWithValidation>
							<!--
							<SelectWithValidation class="mb-4" label="Qual documento deseja utilizar para comprovar sua propriedade?" v-model="land.type_document">
								<option value="car" key="typedoc-1">CAR</option>
								<option value="ccir" key="typedoc-2">CCIR</option>
								<option value="sigesp" key="typedoc-3">SIGESP</option>
								<option value="titulo" key="typedoc-4">Título Definitivo</option>
							</SelectWithValidation>
							<b-field class="file is-primary" :class="{ 'has-name': !!documentFile }">
								<b-upload v-if="land && land.type_document" v-model="documentFile" class="file-label">
									<span class="file-cta">
										<span class="file-label">Selecione o arquivo</span>
										<b-icon class="file-icon ml-4" icon="upload"></b-icon>
									</span>
									<span class="file-name" v-if="documentFile">{{ documentFile.name }}</span>
								</b-upload>
							</b-field>
							<a v-if="land && land.document" :href="urlDocument()">Download do arquivo atual</a>
							-->
						</div>
						<div class="column is-one-third">
							<SelectWithValidation class="mb-4" rules="required" :label="$t('labels.visibility')" v-model="land.visibility">
								<option :value="1" key="visib-0">{{ $t('labels.active') }}</option>
								<option :value="0" key="visib-1">{{ $t('labels.inactive') }}</option>
							</SelectWithValidation>
							<SelectWithValidation v-if="user && user.role.name == 'master'" class="mb-4" rules="required" :label="$t('fields.owner')" v-model="land.user_id">
								<option v-for="p in producers" :value="p.id" :key="p.id">{{ p.full_name }}</option>
							</SelectWithValidation>
							<div class="land-photos">
								<label class="label">{{ $tc('fields.photos') }}</label>
								<div v-if="land.photos && land.photos.length > 0">
									<ul class="photos">
										<li v-for="p in landPhotos" :key="p.id">
											<b-image :src="p.path"></b-image>
											<b-button @click="deletePhoto(p.id)">×</b-button>
										</li>
									</ul>
								</div>
								<b-upload v-model="photos" accept="image/png, image/jpeg, image/gif, image/svg+xml" multiple drag-drop class="upload-button mt-1">
									{{ photos && photos.length > 0 ? `+ ${photos.length}  ${$tc('labels.selected', photos.length)}` : $t('buttons.add') }}
								</b-upload>
								<label class="label mt-5">{{ $tc('fields.aerial_view') }}</label>
								<div v-if="land.photos && land.photos.length > 0">
									<ul class="photos videos">
										<li v-for="p in landAerial" :key="p.id">
											<video v-if="p.path.includes('.mp4')" controls>
												<source :src="p.path" type="video/mp4">
											</video>
											<b-image v-else :src="p.path"></b-image>
											<b-button @click="deletePhoto(p.id)">×</b-button>
										</li>
									</ul>
								</div>
								<b-upload v-model="aerial" accept="image/png, image/jpeg, image/gif, image/svg+xml, video/mp4" multiple drag-drop class="upload-button mt-1">
									{{ aerial && aerial.length > 0 ? `${aerial.length} ${$tc('labels.selected', aerial.length)}` : $t('buttons.add') }}
								</b-upload>
							</div>
							<div :class="{ 'not-showing': !showCoordinates }">
								<label class="label mt-5">{{ $tc('labels.map') }}</label>
								<div ref="map-root" class="land-map"></div>
							</div>
						</div>
					</div>
					<small class="modal-updated" v-if="land.updated_at">{{ $t('labels.last_change') }} {{ format(land.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import { fromLonLat } from 'ol/proj'
import Style from 'ol/style/Style'
import Feature from 'ol/Feature'
import Icon from 'ol/style/Icon'
import validator from 'validator'
import Polygon from 'ol/geom/Polygon'
import 'ol/ol.css'
import { mapState } from 'vuex'
import { VMoney } from 'v-money'
import { urlServer } from '@/services/constants'

export default {
	components: {
		Footer,
		InputWithValidation,
		SelectWithValidation,
		ValidationObserver
	},
	directives: {
		money: VMoney
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			showCoordinates: null,
			land: {},
			photos: [],
			aerial: [],
			formatArea: {
				decimal: '.',
				thousands: '',
				prefix: '',
				precision: 2
			},
			coordinates: [
				{
					lat: '',
					lon: ''
				},
				{
					lat: '',
					lon: ''
				},
				{
					lat: '',
					lon: ''
				}
			],
			visible: false,
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			states: [
				'Acre',
				'Alagoas',
				'Amapá',
				'Amazonas',
				'Bahía Bahía',
				'Ceará',
				'Distrito Federal',
				'Espírito Santo',
				'Goiás',
				'Maranhão',
				'Mato Grosso',
				'Mato Grosso do Sul',
				'Minas Gerais',
				'Pará',
				'Paraíba',
				'Paraná',
				'Pernambuco',
				'Piauí',
				'Rio de Janeiro',
				'Rio Grande do Norte',
				'Rio Grande do Sul',
				'Rondonia',
				'Roraima',
				'Santa Catarina',
				'São Paulo',
				'Sergipe',
				'Tocantins'
			],
			map: null,
			producers: [],
			documentFile: null,
			documents: []
		}
	},
	methods: {
		async getProducers() {
			try {
				const response = await Api.get(`lands/findAllProducers`)
				if (response.status === 200) {
					this.producers = response.data
				}
			} catch(e) {
				console.log(e)
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`lands/findById/${this.id}`)
					if (response.status === 200) {
						this.land = response.data

						if (response.data.coordinates.length > 0) {
							this.coordinates = [ ...response.data.coordinates ]
						}

						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}

				if (this.land.lat || this.land.lon) {
					this.showCoordinates = 1
				}
			}
		},
		getFormData() {
			let formData = new FormData()

			if (this.user && this.user.role.name == 'master') {
				formData.append('user_id', this.land.user_id)
			}

			formData.append('name', this.land.name)
			formData.append('area', this.land.area)
			formData.append('city', this.land.city)
			formData.append('state', this.land.state)
			formData.append('lon', this.land.lon || '')
			formData.append('lat', this.land.lat || '')
			formData.append('user_document_id', this.land.user_document_id || '')
			formData.append('visibility', this.land.visibility)

			this.coordinates.forEach((c, i) => {
				formData.append('coordinates[' + i + '][lat]', c.lat || '')
				formData.append('coordinates[' + i + '][lon]', c.lon || '')
			})

			this.photos.forEach((p) => {
				formData.append('photos[]', p)
			})

			this.aerial.forEach((a) => {
				formData.append('aerial[]', a)
			})

			if (this.documentFile) {
				formData.append('document_file', this.documentFile)
			}

			return formData
		},
		async update() {
			try {
				this.loading = true
				let formData = this.getFormData()
				formData.append('_method', 'put')
				const response = await Api.post(`lands/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/lands')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.lands')]))
					eventHub.$emit('reload-land')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.lands')]))
				}
			} finally {
				this.loading = false
			}
		},
		async store() {
			try {
				this.loading = true
				let formData = this.getFormData()
				const response = await Api.post('lands/store', formData, this.configRequest)
				const { status } = response
				if (status === 201 || status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/lands')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.lands')]))
					eventHub.$emit('reload-land')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		async save() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		deletePhoto(id) {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('labels.warning'),
				message: '<span>' + this.$t('alerts.delete.confirmation') + '<span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('buttons.no'),
				confirmText: this.$t('buttons.yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`lands/deletePhoto/${id}`)
						if (response.status === 200) {
							const findPhoto = this.land.photos.findIndex(p => p.id == id)
							this.land.photos.splice(findPhoto, 1)

							successToast(this.$t('alerts.delete.success_simple'))
						}
					} catch (e) {
						console.log(e)
						errorToast(this.$t('alerts.delete.error_simple'))
					}
				}
			})
		},
		deleteCoordinate(coordinate, index) {
			console.log(coordinate)
			this.coordinates.splice(index, 1)
		},
		addCoordinate() {
			this.coordinates.push({
				lat: '',
				lon: ''
			})
		},
		setPositionMap(lonLat, zoom = 0) {
			this.map.setView(new View({
				center: fromLonLat(lonLat),
				zoom: zoom
			}))
		},
		clearMap(id = 0) {
			this.map.getLayers().forEach((layer) => {
				if (layer && layer.values_) {
					if ((id > 0 && layer.values_.id == id) || (id == 0 && layer.values_.id > 0 && layer.values_.id < 999)) {
						this.map.removeLayer(layer)
					}
				}
			})
		},
		urlDocument() {
			return `${urlServer()}/storage/${this.land.document}`
		},
		async listDocuments(id) {
			if (this.user) {
				try {
					const requestDoc = await Api.get(`user-documents/list-user/${ id ? id : this.user.id}`)

					if (requestDoc.status == 200) {
						this.documents = requestDoc.data
					}
				} catch (e) {
					console.log(e)
				}
			}
		}
	},
	mounted() {
		this.showCoordinates = 0
		this.land.visibility = 1
		this.listDocuments(null)
		this.getProducers()
		this.findById()

		this.map = new Map({
			target: this.$refs['map-root'],
			layers: [
				new TileLayer({
					source: new OSM()
				})
			],
			view: new View({
				zoom: 0,
				center: [0, 0],
				constrainResolution: true
			})
		})
	},
	computed: {
		...mapState('user', ['user']),
		landPhotos() {
			return this.land.photos && this.land.photos.length > 0
				? this.land.photos.filter(p => p.aerial === 0)
				: []
		},
		landAerial() {
			return this.land.photos && this.land.photos.length > 0
				? this.land.photos.filter(p => p.aerial === 1)
				: []
		},
		capacity() {
			return this.land.area ? this.land.area * 200 : 0
		},
		locationValid() {
			if (this.land.lat && this.land.lon) {
				if (validator.isLatLong(`${this.land.lat}, ${this.land.lon}`)) {
					return [this.land.lon, this.land.lat]
				}
			}

			return false
		},
		coordinatesValid() {
			let valid = true
			let coordinatesTemp = []
			this.coordinates.forEach((c) => {
				if (validator.isLatLong(`${c.lat}, ${c.lon}`)) {
					coordinatesTemp.push(fromLonLat([ c.lon, c.lat ]))
				} else {
					valid = false
				}
			})

			return valid ? coordinatesTemp : valid
		}
	},
	watch: {
		locationValid(v) {
			this.clearMap(1000)

			if (v) {
				this.map.addLayer(new VectorLayer({
					id: 1000,
					source: new VectorSource({
						features: [new Feature(new Point(fromLonLat(v)))]
					}),
					style: new Style({
						image: new Icon({
							anchor: [0.5, 1],
							scale: 0.1,
							src: require('@/assets/svg/pin.svg')
						})
					})
				}))

				this.setPositionMap(v, 9)
			} else {
				this.setPositionMap([0, 0], 0)
			}
		},
		coordinatesValid(v) {
			this.clearMap(100)

			if (v) {
				this.map.addLayer(new VectorLayer({
					id: 100,
					source: new VectorSource({
						features: [
							new Feature(new Polygon([v]))
						]
					}),
					style: {
						'stroke-width': 1,
						'stroke-color': [0, 0, 200, 1],
						'fill-color': [0, 0, 200, 0.3]
					}
				}))
			}
		},
		'land.user_id' (v) {
			this.documents = []

			if (v) {
				this.land.user_document_id
				this.listDocuments(v)
			}
		}
	}
}
</script>
